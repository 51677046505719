<template>
	<v-container>
		
			<p class="display-3">
				Craziest research proposal
			</p>
			<br/>
			<p class="display-1">Title:</p>
			<p class="title">
				The impact of <v-text-field solo v-model="textfields[0]"></v-text-field> on the perception of <v-text-field solo v-model="textfields[1]"></v-text-field> :<br/>
				A <v-text-field solo v-model="textfields[2]"></v-text-field> study.
			</p>

			<br/>
			<p class="display-1">Abstract:</p>
			<p class="title">
				<v-text-field solo v-model="textfields[3]"></v-text-field> is a costly and complex problem for <v-text-field solo v-model="textfields[4]"></v-text-field>. By applying the <v-text-field solo v-model="textfields[5]"></v-text-field> methodology, we deconstruct available literature and propose a taxonomy of <v-text-field solo v-model="textfields[6]"></v-text-field>. By evaluation our taxonomy with <v-text-field solo v-model="textfields[7]"></v-text-field>, we uncover significant <v-text-field solo v-model="textfields[8]"></v-text-field>, particular for <v-text-field solo v-model="textfields[9]"></v-text-field>. Our results have high implications for <v-text-field solo v-model="textfields[10]"></v-text-field> ethics.
			</p>

			<br/>
			<p class="display-1">Your words:</p>
			<div class="nouns">
				<div v-for="(number, index) in randomNouns" :key="index" class="subtitle-1 font-weight-medium" v-bind:class="{ active: isActive(nouns[number]) }">
					{{ nouns[number] }}
				</div>
			</div>
		
	</v-container>
</template>

<script>
	// @ is an alias to /src

	export default {
	name: 'Home',
	components: {

	},

		data: () => ({

			textfields: ["","","","","","","","","","",""],

			nouns: [
				"hunting ground",
				"lettuce",
				"kitty",
				"friendly grandma",
				"french chef",
				"antidepressant drug",
				"corn cake",
				"president of the night",
				"candlestick maker",
				"coffee pot",
				"brethren",
				"national security agency",
				"tank",
				"useless brakes",
				"international law enforcement agency",
				"sound barrier",
				"skinny woman",
				"private investor",
				"main people",
				"stock car",
				"elastic band",
				"whole blood",
				"telephone",
				"mad cow disease",
				"scourge upon the earth",
				"rough-skinned newt",
				"karate",
				"pistol",
				"legal warrant",
				"people who are hurt",
				"idea he suggested",
				"party of the first part",
				"place of business",
				"double fault",
				"kitty cat",
				"famous landscape painting",
				"hairy legs",
				"old irish cottage",
				"pocket flask",
				"liquid oxygen",
				"laser beams",
				"preventive strike",
				"dingle berry",
				"reading party",
				"generalized bond",
				"indirect expression",
				"messiness",
				"love of her life",
				"trust fund",
				"volcanic crater",
				"travel guidebook",
				"electric furnace",
				"internal respiration",
				"police squad",
				"mad-dog skullcap",
				"sneaky criminal",
				"new version",
				"keepsake machete",
				"gaming laptop",
				"hissy fit",
				"dog poop",
				"dragon",
				"mediation",
				"patrolman",
				"pervert",
				"toilet seat",
				"haunted graveyard",
				"really tough guy",
				"twinkling uncleanness",
				"wrinkle",
				"personal credit line",
				"multi-billionaire",
				"mental disorder",
				"sweet tailpipe",
				"boiling water",
				"deer antler",
				"background story",
				"mood",
				"nibblets",
				"striped hyena",
				"weed wacker",
				"man of my dreams",
				"pilot chart",
				"suckling pig",
				"sight of the girl",
				"idiot",
				"wiley coyote",
				"crime scene",
				"most beautiful girl",
				"gothic romance",
				"first born",
				"tire iron",
				"planetary house",
				"lieutenant governor",
				"manure",
				"rocket science",
				"ways of world",
				"wild, wild sheep",
				"kind and gentle folk",
				"ice cream",
				"diplomatic negotiation",
				"marshmallow",
				"internal control",
				"totem pole",
				"remnant of chaos",
				"space emulator",
				"serious question",
				"general knowledge",
				"pile of decomposition",
				"change for good",
				"ship’s captain",
				"hiking boots",
				"lips",
				"big polar bear",
				"erie canal",
				"sphere of influence",
				"temper tantrum",
				"elegant question",
				"nature trail",
				"indestructible empire",
				"dentist’s drill",
				"eskimo",
				"indiscriminate killer",
				"monkey",
				"stick figure",
				"day before",
				"matrimonial law",
				"sealed off tomb",
				"unit of unholy depth",
				"broken promises",
				"notion of love",
				"obviousness",
				"fluffy clouds",
				"shoe string",
				"body",
				"stir stick",
				"people",
				"divergent thinking",
				"latest version",
				"baptist church",
				"dirty coffee cup",
				"towelette",
				"amish folk",
				"pillow",
				"roadblock",
				"disorder",
				"big jerk",
				"toilet",
				"snakehead",
				"descending color",
				"merry bells",
				"knight’s service",
				"life raft",
				"radio beam",
				"brainchild",
				"orbital plane",
				"confusing situation",
				"manipulating ass",
				"microwaved eggs",
				"shivering toes",
				"natural history",
				"positive feedback",
				"modern times",
				"mistress",
				"animal psychology department",
				"sewing-machine operator",
				"light bulb",
				"jet ski",
				"constant reminder",
				"baby",
				"snake",
				"mathematics department",
				"video game",
				"musical chairs",
				"pancake",
				"skull and crossbones",
				"fluffy slippers",
				"nonbeliever",
				"phonograph record",
				"friendly claim",
				"willow",
				"trooper",
				"clan member",
				"bouncy ball",
				"tentacle",
				"police department",
				"bored child",
				"thunder legs",
				"abnormal behavior",
				"drug addict",
				"meeting with my mom",
				"control account",
				"mimicker",
				"overlord",
				"informal request",
				"railroad worm",
				"nudist camp",
				"mental case",
				"date of improvement",
				"sheep dip",
				"toast",
				"polkdot",
				"pizza cutter",
				"locker room",
				"litter pan",
				"wiener",
				"chicken stew",
				"jungle psychology",
				"extremity",
				"invisibility cloak",
				"biology lab",
				"fact that i don’t think",
				"fairy lantern",
			],

			adjectives: [],
			
		}),

		computed: {
			randomNouns() {
				let length = 35
				let max = 212

				function randomArray(length, max) {
					return Array.apply(null, Array(length)).map(function() {
						return Math.round(Math.random() * max);
					});
				}

				let uniReturnArray = [...new Set(randomArray(length, max))]

				if(uniReturnArray.length < length) {
					let amountOfNewNumbers = length - uniReturnArray.length

					for(let i = 0; i < amountOfNewNumbers; i++) {
						let newsingleNumber = Math.round(Math.random() * max)
						while(uniReturnArray.includes(newsingleNumber)) {
							newsingleNumber = Math.round(Math.random() * max)
						}
						uniReturnArray.push(newsingleNumber)
					}
				}
				window.console.log(uniReturnArray.length)
				return uniReturnArray
			}
		},
		

		methods: {
			isActive(noun) {
				if(this.textfields.includes(noun)) {
					return true
				}
			}

		},
	}
</script>

<style>

.v-input {
	display: inline-flex !important;
}

.nouns {
  column-count: 3;
}

.active {
	color: #ff8a50;
}

</style>