<template>
  <v-app id="app">
    <v-content>
      <transition name="component-fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-content>
  </v-app>
</template>
<script>

export default {
  name: 'App',

  components: {

  },

  created () {
      this.$vuetify.theme.dark = true
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none;
}

.router-link-exact-active {
    background-color: rgba(0, 0, 0, 0.26);
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .2s ease;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
</style>